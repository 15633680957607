import React, { useState } from 'react';
import '../css/editcamping.css';

const EditCamping = () => {
    const [searchString, setSearchString] = useState('');
    const [campings, setCampings] = useState([]);
    const [selectedCamping, setSelectedCamping] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null); // State for submission status

    const handleSearchChange = (event) => {
        setSearchString(event.target.value);
    };

    const handleSearchClick = () => {
        if (searchString.length >= 2) {
            setLoading(true);
            fetch(`https://us-central1-campapp-195fe.cloudfunctions.net/searchCampings?searchString=${searchString}`)
                .then(response => response.json())
                .then(data => {
                    setCampings(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        } else {
            setCampings([]);
        }
    };

    const handleCampingSelect = (camping) => {
        setSelectedCamping(camping);
        setSubmissionStatus(null); // Clear submission status on new selection
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setSelectedCamping(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
        }));
    };

    const handleSave = () => {
        if (selectedCamping) {
            const updatedCamping = {
                ...selectedCamping,
                lastUpdated: new Date().toISOString().split('T')[0] // YYYY-MM-DD format
            };

            // Retrieve token from localStorage
            const token = localStorage.getItem('authToken');

            fetch(`https://us-central1-campapp-195fe.cloudfunctions.net/updateCampingByCode?code=${selectedCamping.code}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Add Bearer token here
                },
                body: JSON.stringify(updatedCamping)
            })
                .then(response => response.text()) // Expect text response
                .then(message => {
                    console.log('Response:', message);
                    setSubmissionStatus('success'); // Set submission status to success
                    setSelectedCamping(null); // Clear selected camping to collapse fields
                    setCampings([]); // Clear search results
                })
                .catch(error => {
                    console.error('Error updating camping:', error);
                    alert('Failed to update camping. Please try again.'); // Show alert on error
                    setSubmissionStatus('error'); // Set submission status to error
                });
        }
    };

    return (
        <div className="edit-camping">
            <h2>Korrigera camping</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Sök camping..."
                    value={searchString}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <button onClick={handleSearchClick} className="search-button">
                    Sök
                </button>
            </div>
            {loading && <p>Laddar...</p>}
            {searchString.length >= 2 && !loading && (
                <ul className="camping-list">
                    {campings.map(camping => (
                        <li
                            key={camping.id}
                            onClick={() => handleCampingSelect(camping)}
                            className="camping-item"
                        >
                            <span>{camping.code}</span> - <span>{camping.title}</span>
                        </li>
                    ))}
                </ul>
            )}
            {selectedCamping && !submissionStatus && (
                <div className="camping-details">
                    <h3>{selectedCamping.title}</h3>
                    <form className="camping-form">
                        {/* Title field */}
                        <div className="form-group">
                            <label>Title:</label>
                            <input
                                type="text"
                                name="title"
                                value={selectedCamping.title}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Code:</label>
                            <input
                                type="text"
                                name="code"
                                value={selectedCamping.code}
                                onChange={handleChange}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label>Telefonnummer:</label>
                            <input
                                type="text"
                                name="phone"
                                value={selectedCamping.phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={selectedCamping.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Hemsida:</label>
                            <input
                                type="text"
                                name="webPage"
                                value={selectedCamping.webPage}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Pris:</label>
                            <input
                                type="text"
                                name="price"
                                value={selectedCamping.price}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Platser/Varav el:</label>
                            <input
                                type="text"
                                name="places"
                                value={selectedCamping.places}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Öppettider:</label>
                            <input
                                type="text"
                                name="open"
                                value={selectedCamping.open}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Text:</label>
                            <textarea
                                name="text"
                                value={selectedCamping.text}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Text (English):</label>
                            <textarea
                                name="textEnglish"
                                value={selectedCamping.textEnglish}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Röster:</label>
                            <input
                                type="text"
                                name="voters"
                                value={selectedCamping.voters}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Betyg:</label>
                            <input
                                type="text"
                                name="ranking"
                                value={selectedCamping.ranking}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Typ:</label>
                            <input
                                type="text"
                                name="type"
                                value={selectedCamping.type}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Latitude:</label>
                            <input
                                type="text"
                                name="lat"
                                value={selectedCamping.lat}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Longitude:</label>
                            <input
                                type="text"
                                name="lng"
                                value={selectedCamping.lng}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Country:</label>
                            <select
                                name="country"
                                value={selectedCamping.country}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Country</option>
                                <option value="se">Sweden</option>
                                <option value="no">Norway</option>
                                <option value="dk">Denmark</option>
                            </select>
                        </div>
                        {/* Electricity field */}
                        <div className="form-group">
                            <label>Electricity:</label>
                            <input
                                type="text"
                                name="electricity"
                                value={selectedCamping.electricity || ''}
                                onChange={handleChange}
                            />
                        </div>
                        {/* Currency field */}
                        <div className="form-group">
                            <label>Currency:</label>
                            <input
                                type="text"
                                name="currency"
                                value={selectedCamping.currency || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Internet:</label>
                            <label className="switch-container">
                                <input
                                    type="checkbox"
                                    name="internet"
                                    checked={selectedCamping.internet === 1}
                                    onChange={handleChange}
                                />
                                <span className="switch-slider"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Latrine:</label>
                            <label className="switch-container">
                                <input
                                    type="checkbox"
                                    name="latrine"
                                    checked={selectedCamping.latrine === 1}
                                    onChange={handleChange}
                                />
                                <span className="switch-slider"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Pets Allowed:</label>
                            <label className="switch-container">
                                <input
                                    type="checkbox"
                                    name="pets"
                                    checked={selectedCamping.pets === 1}
                                    onChange={handleChange}
                                />
                                <span className="switch-slider"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Pool:</label>
                            <label className="switch-container">
                                <input
                                    type="checkbox"
                                    name="pool"
                                    checked={selectedCamping.pool === 1}
                                    onChange={handleChange}
                                />
                                <span className="switch-slider"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Sea View:</label>
                            <label className="switch-container">
                                <input
                                    type="checkbox"
                                    name="seaView"
                                    checked={selectedCamping.seaView === 1}
                                    onChange={handleChange}
                                />
                                <span className="switch-slider"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Service Store:</label>
                            <label className="switch-container">
                                <input
                                    type="checkbox"
                                    name="serviceStore"
                                    checked={selectedCamping.serviceStore === 1}
                                    onChange={handleChange}
                                />
                                <span className="switch-slider"></span>
                            </label>
                        </div>
                        <button type="button" onClick={handleSave} className="save-button">
                            Spara ändringar
                        </button>
                    </form>
                </div>
            )}
            {submissionStatus === 'success' && <p className="success-message">Uppdateringarna lyckades!</p>}
        </div>
    );
};

export default EditCamping;
