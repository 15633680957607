import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Features from './components/Features';
import About from './components/About';
import NewsPage from './components/NewsPage';
import CampingDetails from './components/CampingDetails'; // Import the new CampingDetails component
import ArticlePage from './components/ArticlePage';

import Admin from './components/Admin';
import Footer from './components/Footer';
import Under350 from './components/under350';


const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/camping" element={<CampingDetails />} />
        <Route path="/article" element={<ArticlePage />} />
        <Route path="/under350" element={<Under350 />} />


      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
