import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import '../css/addarticle.css';

const AddArticle = () => {
    const [articleData, setArticleData] = useState({
        documentID: '', // Add documentID to state
        author: '',
        publishedDate: new Date().toISOString().split('T')[0], // Default to today
        title: '',
        content: ''
    });

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setArticleData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleQuillChange = (value) => {
        setArticleData(prevState => ({
            ...prevState,
            content: value
        }));
    };

    const validateForm = () => {
        const requiredFields = ['documentID', 'author', 'title', 'content'];
        for (let field of requiredFields) {
            if (!articleData[field].trim()) {
                setError(`"${field}" is required.`);
                return false;
            }
        }
        setError('');
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(`https://us-central1-campapp-195fe.cloudfunctions.net/addArticle?documentID=${encodeURIComponent(articleData.documentID)}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        author: articleData.author,
                        publishedDate: articleData.publishedDate,
                        title: articleData.title,
                        content: articleData.content
                    })
                });

                // Check if the response is okay
                if (response.ok) {
                    // If the response is text, just read it as text
                    const resultText = await response.text();
                    setSuccessMessage(resultText);
                    setArticleData({
                        documentID: '',
                        author: '',
                        publishedDate: '',
                        title: '',
                        content: ''
                    });
                } else {
                    // Handle HTTP errors
                    const errorText = await response.text();
                    setError(`Failed to add article: ${errorText}`);
                }
            } catch (error) {
                console.error('Error adding article:', error);
                setError('Failed to add article. Please try again.');
            }
        }
    };


    return (
        <div className="add-article">
            <h2>Add Article</h2>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form className="article-form">
                <div className="form-group">
                    <label>Document ID <span className="required">*</span>:</label>
                    <input
                        type="text"
                        name="documentID"
                        value={articleData.documentID}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Author <span className="required">*</span>:</label>
                    <input
                        type="text"
                        name="author"
                        value={articleData.author}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Published Date:</label>
                    <input
                        type="date"
                        name="publishedDate"
                        value={articleData.publishedDate}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Title <span className="required">*</span>:</label>
                    <input
                        type="text"
                        name="title"
                        value={articleData.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Content <span className="required">*</span>:</label>
                    <ReactQuill
                        value={articleData.content}
                        onChange={handleQuillChange}
                        theme="snow"
                        modules={modules}
                    />
                </div>
                <button type="button" onClick={handleSubmit} className="save-button">
                    Add Article
                </button>
            </form>
        </div>
    );
};

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image']
    ]
};

export default AddArticle;
