import React from 'react';
import '../css/features.css'
import '../css/global.css'
import phone2 from '../assets/phone2.png'; // Replace with actual paths
import phone3 from '../assets/phone3.png';
import phone1 from '../assets/phone1.png';
import { useTranslation } from 'react-i18next';


const Features = () => {
    const { t } = useTranslation();

    return (
        <section id="features">
            <h2>{t('featuresHeading')}</h2>
            <div className="feature-container">
                <div className="feature">
                    <img src={phone2} alt="Campsite Finder" className="feature-image" />
                    <div className="feature-text">
                        <h3>{t('feature1heading')}</h3>
                        <p>{t('feature1')}</p>
                    </div>
                </div>
                <div className="feature">
                    <img src={phone1} alt="Detailed info" className="feature-image" />
                    <div className="feature-text">
                        <h3>{t('feature3heading')}</h3>
                        <p>{t('feature3')}</p>
                    </div>
                </div>
                <div className="feature">
                    <img src={phone3} alt="News Feed" className="feature-image" />
                    <div className="feature-text">
                        <h3>{t('feature2heading')}</h3>
                        <p>{t('feature2')}</p>
                    </div>
                </div>


            </div>
        </section>
    );
};

export default Features;
