import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import lowPricesCamping from '../lowpricescamping.json'; // Adjust the path accordingly
import L from 'leaflet';
import '../css/under350.css';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import appStoreBadge from '../assets/appstore.png'; // Path to your App Store badge image
import playStoreBadge from '../assets/androidbadge.png'; // Path to your Play Store badge image
import { analytics } from '../firebaseConfig';  // Import the Firebase config
import { logEvent } from 'firebase/analytics'; // Import logEvent

// Fix for the default marker icon not appearing
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MarkerIcon = ({ isHighlighted }) => {
    return L.divIcon({
        className: 'custom-icon',
        html: `<div style="background-color: ${isHighlighted ? 'red' : '#34A364'}; width: 16px; height: 16px; border-radius: 50%;"></div>`,
    });
};

const Under350 = () => {
    const [campings, setCampings] = useState([]);
    const [highlightedCode, setHighlightedCode] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState('');
    const [selectedMaxPrice, setSelectedMaxPrice] = useState('');

    useEffect(() => {
        // Log a page view event to Firebase Analytics
        logEvent(analytics, 'under350_page_view', { page: 'Under 350 SEK Camping List' });
    }, []);


    useEffect(() => {
        // Load and sort campings by title
        const sortedCampings = [...lowPricesCamping].sort((a, b) => a.title.localeCompare(b.title));
        setCampings(sortedCampings);
    }, []);

    const handleIOSDownloadClick = () => {
        // Log the iOS download click event
        logEvent(analytics, 'under350_download_ios', { page: 'Under 350 SEK Camping List' });
    };

    const handleAndroidDownloadClick = () => {
        // Log the Android download click event
        logEvent(analytics, 'under350_download_android', { page: 'Under 350 SEK Camping List' });
    };

    // Get unique counties from the campings data
    const counties = [...new Set(lowPricesCamping.map(camping => camping.county))].sort();

    // Filter campings based on the selected county and price
    const filteredCampings = campings.filter(camping => {
        // Filter by county
        if (selectedCounty && camping.county !== selectedCounty) return false;

        // Calculate the filter price
        let priceRange = camping.price.split('-');
        let price = parseInt(priceRange[priceRange.length - 1], 10); // Take the last value in case of a range
        let electricity = parseInt(camping.electricity, 10);

        if (electricity === -1) electricity = 0; // Electricity included in price
        if (isNaN(electricity) || electricity === 0) electricity = 0; // Missing or no information

        const filterPrice = price + electricity;

        // Filter by calculated price
        if (selectedMaxPrice && filterPrice > selectedMaxPrice) return false;

        return true;
    });

    return (
        <div className="under-350-container">
            <div className="header-350">
                <h1>Campingplatser under 350 SEK</h1>
                <h4>Listan uppdaterad augusti 2024</h4>
                <p>Vi reserverar oss för eventuella fel. Om du ser nåt som inte stämmer tveka inte att maila oss på <a href="mailto:info@campapp.se">info@campapp.se</a></p>
                <p>Ladda ner appen här</p>

                <div className="download-buttons">
                    <a
                        href="https://apps.apple.com/app/campapp-sverige/id1567206237"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleIOSDownloadClick} // Add event handler for iOS download button
                    >
                        <img src={appStoreBadge} alt="Download on the App Store" className="store-badge" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.alstersjoapps.campingapp"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleAndroidDownloadClick} // Add event handler for Android download button
                    >
                        <img src={playStoreBadge} alt="Get it on Google Play" className="store-badge" />
                    </a>
                </div>

            </div>
            <div className="filters-350">
                <FormControl variant="outlined" style={{ marginRight: '10px', marginBottom: '10px' }}>
                    <InputLabel>Län</InputLabel>
                    <Select
                        value={selectedCounty}
                        onChange={(e) => setSelectedCounty(e.target.value)}
                        label="Län"
                    >
                        <MenuItem value="">
                            <em>Alla län</em>
                        </MenuItem>
                        {counties.map(county => (
                            <MenuItem key={county} value={county}>
                                {county}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ marginBottom: '10px' }}>
                    <InputLabel>Maxpris</InputLabel>
                    <Select
                        value={selectedMaxPrice}
                        onChange={(e) => setSelectedMaxPrice(e.target.value)}
                        label="Maxpris"
                    >

                        <MenuItem value={100}>100 SEK</MenuItem>
                        <MenuItem value={200}>200 SEK</MenuItem>
                        <MenuItem value={300}>300 SEK</MenuItem>
                        <MenuItem value={350}>350 SEK</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="content-350">
                <div className="camping-list-350">
                    <List>
                        {filteredCampings.map((camping) => (
                            <ListItem
                                key={camping.code}
                                button
                                component="a"
                                href={`https://campapp.se/camping?code=${camping.hash}`} // Full URL
                                target="_blank"
                                rel="noopener noreferrer"
                                onMouseEnter={() => setHighlightedCode(camping.code)}
                                onMouseLeave={() => setHighlightedCode(null)}
                            >
                                <ListItemText
                                    primaryTypographyProps={{ color: "#34A364" }}
                                    primary={camping.title}
                                    secondary={
                                        <>
                                            <div>Pris: {camping.price} kr</div>
                                            <div>El: {camping.electricity === -1 ? 'Inkluderat' : camping.electricity === 0 ? 'Vi saknar information' : camping.electricity === "" ? 'Vi saknar information' : camping.electricity + " kr"}</div>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="open-in-new">
                                        <OpenInNewIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div className="camping-map-350">
                    <MapContainer
                        center={[62.623881100792075, 15.035977078358231]} // Default center
                        zoom={5}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {filteredCampings.map(camping => (
                            <Marker
                                key={camping.code}
                                position={[parseFloat(camping.lat), parseFloat(camping.lng)]}
                                icon={MarkerIcon({ isHighlighted: highlightedCode === camping.code })}
                            >
                                <Popup>{camping.title}</Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>
            </div>
        </div>
    );
};

export default Under350;
