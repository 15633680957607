import React from 'react';
import NewsList from './NewsList';
import '../css/news-list.css';

const NewsPage = () => {


    return (
        <div>
            <NewsList />
        </div>
    );
};

export default NewsPage;
