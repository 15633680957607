import React, { useState, useEffect } from 'react';
import '../css/addcamping.css';

const AddCamping = () => {
    const [campingData, setCampingData] = useState({
        id: '',
        title: '',
        electricity: '',
        currency: '',
        pets: '0',
        serviceStore: '0',
        code: '',
        lng: '',
        voters: 0,
        pool: '0',
        quickStop: '0',
        webPage: '',
        type: '1',
        seaView: '0',
        latrine: '0',
        phone: '',
        ranking: '0',
        text: '',
        email: '',
        internet: 0,
        lat: '',
        country: 'SE',
        lastUpdated: '',
        places: '',
        price: '',
        textEnglish: '',
        open: ''
    });

    const [error, setError] = useState('');
    const [prefixes, setPrefixes] = useState({
        DK: '',
        NO: '',
        SE: ''
    });

    // Fetch the highest prefixes when the component mounts
    useEffect(() => {
        const fetchPrefixes = async () => {
            try {
                const response = await fetch('https://us-central1-campapp-195fe.cloudfunctions.net/getHighestCodeByPrefix?prefixes=DK,NO,SE');
                const data = await response.json();
                const prefixesMap = data.reduce((acc, item) => {
                    acc[item.prefix] = item.highestCode;
                    return acc;
                }, {});

                setPrefixes(prefixesMap);
            } catch (error) {
                console.error('Error fetching prefixes:', error);
                setError('Failed to fetch prefixes.');
            }
        };

        fetchPrefixes();
    }, []);

    // Generate a new code based on the selected country
    const generateCode = (countryPrefix) => {
        const uppercasePrefix = countryPrefix.toUpperCase();
        const highestCode = prefixes[uppercasePrefix];
        if (!highestCode) return '';

        const numericPart = parseInt(highestCode.substring(2), 10);
        const newCodeNumericPart = (numericPart + 1).toString().padStart(4, '0');

        return `${uppercasePrefix}${newCodeNumericPart}`;
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setCampingData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
        }));

        // If country changes, generate a new code
        if (name === 'country') {
            const newCode = generateCode(value.toUpperCase());
            setCampingData(prevState => ({
                ...prevState,
                code: newCode
            }));
        }
    };

    const validateForm = () => {
        if (!campingData.title.trim()) {
            setError('Title is required.');
            return false;
        }
        if (!campingData.lat.trim() || !campingData.lng.trim()) {
            setError('Latitude and Longitude are required.');
            return false;
        }
        if (!campingData.country) {
            setError('Country is required.');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            const token = localStorage.getItem('authToken');
            fetch('https://us-central1-campapp-195fe.cloudfunctions.net/addCampingDocument', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(campingData)
            })
                .then(response => response.text())
                .then(message => {
                    console.log('Response:', message);
                    alert('Camping added successfully!');
                    setCampingData({
                        id: '',
                        title: '',
                        electricity: '',
                        currency: '',
                        pets: '0',
                        serviceStore: '0',
                        code: '',
                        lng: '',
                        voters: 0,
                        pool: '0',
                        quickStop: '0',
                        webPage: '',
                        type: '1',
                        seaView: '0',
                        latrine: '0',
                        phone: '',
                        ranking: '0',
                        text: '',
                        email: '',
                        internet: 0,
                        lat: '',
                        country: 'SE',
                        lastUpdated: '',
                        places: '',
                        price: '',
                        textEnglish: '',
                        open: ''
                    });
                })
                .catch(error => {
                    console.error('Error adding camping:', error);
                    alert('Failed to add camping. Please try again.');
                });
        }
    };

    return (
        <div className="add-camping">
            <h2>Add New Camping</h2>
            {error && <p className="error-message">{error}</p>}
            <form className="camping-form">
                <div className="form-group">
                    <label>Code:</label>
                    <input
                        type="text"
                        name="code"
                        value={campingData.code}
                        onChange={handleChange}
                        disabled
                    />
                </div>
                <div className="form-group">
                    <label>Title <span className="required">*</span>:</label>
                    <input
                        type="text"
                        name="title"
                        value={campingData.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Electricity:</label>
                    <input
                        type="text"
                        name="electricity"
                        value={campingData.electricity}
                        onChange={handleChange}
                    />
                </div>
                <div class="form-group">
                    <label>Currency:</label>
                    <input
                        type="text"
                        name="currency"
                        value={campingData.currency}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Phone:</label>
                    <input
                        type="text"
                        name="phone"
                        value={campingData.phone}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={campingData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Web Page:</label>
                    <input
                        type="text"
                        name="webPage"
                        value={campingData.webPage}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Price:</label>
                    <input
                        type="text"
                        name="price"
                        value={campingData.price}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Places:</label>
                    <input
                        type="text"
                        name="places"
                        value={campingData.places}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Open:</label>
                    <input
                        type="text"
                        name="open"
                        value={campingData.open}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Description:</label>
                    <textarea
                        name="text"
                        value={campingData.text}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Description (English):</label>
                    <textarea
                        name="textEnglish"
                        value={campingData.textEnglish}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Voters:</label>
                    <input
                        type="number"
                        name="voters"
                        value={campingData.voters}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Rank:</label>
                    <input
                        type="number"
                        name="ranking"
                        value={campingData.ranking}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Type:</label>
                    <input
                        type="text"
                        name="type"
                        value={campingData.type}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Latitude <span className="required">*</span>:</label>
                    <input
                        type="text"
                        name="lat"
                        value={campingData.lat}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Longitude <span className="required">*</span>:</label>
                    <input
                        type="text"
                        name="lng"
                        value={campingData.lng}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Country <span className="required">*</span>:</label>
                    <select
                        name="country"
                        value={campingData.country}
                        onChange={handleChange}
                    >
                        <option value="">Select Country</option>
                        <option value="se">Sweden</option>
                        <option value="no">Norway</option>
                        <option value="dk">Denmark</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Internet:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="internet"
                            checked={campingData.internet === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <div className="form-group">
                    <label>Latrine:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="latrine"
                            checked={campingData.latrine === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <div className="form-group">
                    <label>Pets Allowed:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="pets"
                            checked={campingData.pets === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <div className="form-group">
                    <label>Pool:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="pool"
                            checked={campingData.pool === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <div className="form-group">
                    <label>Sea View:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="seaView"
                            checked={campingData.seaView === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <div className="form-group">
                    <label>Quick Stop:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="quickStop"
                            checked={campingData.quickStop === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <div className="form-group">
                    <label>Service Store:</label>
                    <label className="switch-container">
                        <input
                            type="checkbox"
                            name="serviceStore"
                            checked={campingData.serviceStore === 1}
                            onChange={handleChange}
                        />
                        <span className="switch-slider"></span>
                    </label>
                </div>
                <button type="button" onClick={handleSubmit}>Add Camping</button>
            </form>
        </div>
    );
};

export default AddCamping;
