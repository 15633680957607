import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/news-list.css';
import flagEN from '../assets/en.png';
import flagSV from '../assets/sv.png';
import flagNO from '../assets/no.png';
import flagDK from '../assets/dk.png';
import flagDE from '../assets/de.png';

// Function to get the flag image source based on language code
const getFlagSrc = (languageCode) => {
    switch (languageCode) {
        case 'SV':
            return flagSV;
        case 'DK':
            return flagDK;
        case 'NO':
            return flagNO;
        case 'DE':
            return flagDE;
        case 'EN':
            return flagEN;
        default:
            return flagEN; // Fallback image
    }
};

// Function to interleave ads every third item, starting with an ad
const interleaveAds = (regularNews, ads) => {
    const result = [];
    let adIndex = 0;

    // If there are ads, start with the first ad
    if (ads.length > 0) {
        result.push(ads[adIndex++]);
    }

    for (let i = 0; i < regularNews.length; i++) {
        if ((result.length + 1) % 3 === 0 && adIndex < ads.length) {
            result.push(ads[adIndex++]);
        }
        result.push(regularNews[i]);
    }

    // Add any remaining ads at the end
    while (adIndex < ads.length) {
        result.push(ads[adIndex++]);
    }

    return result;
};

const NewsList = () => {
    const { i18n, t } = useTranslation(); // Hook to access i18n instance
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const language = i18n.language.toUpperCase(); // Get the current language from i18n

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch('https://us-central1-campapp-195fe.cloudfunctions.net/getAllNews');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                // Split into ads and regular news
                const ads = data.filter(item => item.isAd);
                const regularNews = data.filter(item => !item.isAd);

                // Sort regular news by the 'added' date in descending order
                const sortedRegularNews = regularNews.sort((a, b) => new Date(b.added) - new Date(a.added));

                // Interleave ads every third position, starting with an ad
                const interleavedNews = interleaveAds(sortedRegularNews, ads);

                setNews(interleavedNews);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    if (loading) return <p>{t('loadingNews')}</p>;
    if (error) return <p>{t('error')} {error}</p>;

    return (
        <div className="news-list">
            <h2>{t('news')}</h2>
            {news.length === 0 ? (
                <p>{t('noNews')}</p>
            ) : (
                <ul>
                    {news.map(item => {
                        const summary = item[`summary${language}`] || item.summaryEN;
                        const adPrefix = item.isAd ? `${t('adFrom')} ${item.publisher}: ` : '';
                        return (
                            <li key={item.id}>
                                <h3>{item[`title${language}`] || item.titleEN}</h3>
                                <p>{adPrefix}{summary}</p>
                                <p><strong>{t('publishdate')}</strong> {item.added}</p>
                                <p><strong>{t('publisher')}</strong> {item.publisher}</p>
                                <p><strong>{t('originalLanguage')}</strong>
                                    <img
                                        src={getFlagSrc(item.language)}
                                        alt={item.language}
                                        style={{ width: '20px', height: '15px', marginLeft: '10px' }}
                                    />
                                </p>
                                <a href={item.URL} target="_blank" rel="noopener noreferrer">{t('readMore')}</a>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default NewsList;
