import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebaseconfig';
import Login from './Login';
import AddCamping from './AddCamping';
import EditCamping from './EditCamping';
import AddArticle from './AddArticle';

import Statistics from './Statistics';
import DeleteCamping from './Delete';
import AddNews from './AddNews';
import '../css/admin.css'
import '../css/global.css'

const Admin = () => {
    const [user, setUser] = useState(null);
    const [activeTab, setActiveTab] = useState('addCamping');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log('User signed out');
            })
            .catch((error) => {
                console.error('Error signing out: ', error);
            });
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'addCamping':
                return <AddCamping />;
            case 'editCamping':
                return <EditCamping />;

            case 'statistics':
                return <Statistics />;
            case 'delete':
                return <DeleteCamping />;
            case 'addNews':
                return <AddNews />;
            case 'addArticle':
                return <AddArticle />;
            default:
                return null;
        }
    };

    if (!user) {
        return <Login />;
    }

    return (
        <section id="admin">
            <h2>Admin Dashboard</h2>
            <p>Vad önskar du göra idag?</p>
            <div className="tab-buttons">
                <button
                    className={activeTab === 'addCamping' ? 'active' : ''}
                    onClick={() => setActiveTab('addCamping')}
                >
                    Lägg till camping
                </button>
                <button
                    className={activeTab === 'editCamping' ? 'active' : ''}
                    onClick={() => setActiveTab('editCamping')}
                >
                    Korrigera camping
                </button>
                <button
                    className={activeTab === 'statistics' ? 'active' : ''}
                    onClick={() => setActiveTab('statistics')}
                >
                    Statistik
                </button>
                <button
                    className={activeTab === 'delete' ? 'active' : ''}
                    onClick={() => setActiveTab('delete')}
                >
                    Ta bort camping
                </button>
                <button
                    className={activeTab === 'addNews' ? 'active' : ''}
                    onClick={() => setActiveTab('addNews')}
                >
                    Lägg till nyheter
                </button>
                <button
                    className={activeTab === 'addArticle' ? 'active' : ''}
                    onClick={() => setActiveTab('addArticle')}
                >
                    Lägg till artikel/post
                </button>

            </div>
            <div className="tab-content">
                {renderTabContent()}
            </div>
            <button className="logout-button" onClick={handleLogout}>
                Log Out
            </button>
        </section>
    );
};

export default Admin;
