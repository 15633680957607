import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet's CSS
import '../css/campingDetails.css'; // Import the custom CSS for styling
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// Import icons
import icLatrine from '../assets/ic_latrine.png';
import icLatrineDisable from '../assets/ic_latrine_disable.png';
import icPetsAllowed from '../assets/ic_pets_allowed.png';
import icPetsAllowedDisable from '../assets/ic_pets_allowed_disable.png';
import icPool from '../assets/ic_pool.png';
import icPoolDisable from '../assets/ic_pool_disable.png';
import icShop from '../assets/ic_shop.png';
import icShopDisable from '../assets/ic_shop_disable.png';
import icNearLake from '../assets/ic_near_lake.png';
import icNearLakeDisable from '../assets/ic_near_lake_disable.png';
import icPhone from '../assets/ic_phone.png';
import icEmail from '../assets/ic_email.png';
import icUrl from '../assets/ic_url.png'; // Import the URL icon

// Import ranking icons
import icRankFull from '../assets/ic_rankfull.png';
import icRankHalf from '../assets/ic_rankhalf.png';
import icRankNone from '../assets/ic_ranknone.png';
import icRankQuarter from '../assets/ic_rankquarter.png';
import icRankThreeQuarter from '../assets/ic_rankthreequarter.png';

import customMarkerCamping from '../assets/ic_camping.png'; // Update path as needed
import customMarkerStell from '../assets/ic_stellplatz.png'; // Update path as needed

import L from 'leaflet'; // Import Leaflet

const CampingDetails = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const hash = query.get('code'); // Extract 'code' from query parameters
    const [camping, setCamping] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentLanguage = i18next.language;

    useEffect(() => {
        if (!hash) {
            setError(t('errorNoCode'));
            setLoading(false);
            return;
        }

        const url = `https://us-central1-campapp-195fe.cloudfunctions.net/getCampingByHashedCode?code=${hash}`;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(t('errorCamping'));
                }
                return response.json();
            })
            .then(data => {
                setCamping(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, [hash, t]);

    if (loading) return <p>{t('loadingCamping')}</p>;
    if (error) return <p>{t('error')} {error}</p>;
    if (!camping) return <p>{t('noCamping')} </p>;

    const position = [parseFloat(camping.lat), parseFloat(camping.lng)]; // Latitude and Longitude for map
    // Create a custom icon
    const customIcon = L.icon({
        iconUrl: camping.type === 1 ? customMarkerCamping : customMarkerStell,
        iconSize: [32, 32], // size of the icon
        iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
    });
    // Function to generate star images based on the ranking
    const getRankingImages = (rank) => {
        const fullStar = icRankFull;
        const halfStar = icRankHalf;
        const quarterStar = icRankQuarter;
        const threeQuarterStar = icRankThreeQuarter;
        const noneStar = icRankNone;

        let stars = [];
        const fullStarsCount = Math.floor(rank);
        const decimalPart = rank - fullStarsCount;

        // Add full stars
        for (let i = 0; i < fullStarsCount; i++) {
            stars.push(fullStar);
        }

        // Add the appropriate partial star
        if (decimalPart >= 0.825) {
            stars.push(fullStar);
        } else if (decimalPart >= 0.65) {
            stars.push(threeQuarterStar);
        }
        else if (decimalPart >= 0.35) {
            stars.push(halfStar);
        } else if (decimalPart >= 0.15) {
            stars.push(quarterStar);
        }

        // Fill remaining stars with none
        while (stars.length < 5) {
            stars.push(noneStar);
        }

        return stars;
    };

    const rankingImages = getRankingImages(camping.ranking);

    // Determine the description based on the country
    let description;

    // Check if the current language matches the camping's country code
    if (currentLanguage === 'no' && camping.country === 'no') {
        description = camping.text;
    } else if (currentLanguage === 'dk' && camping.country === 'dk') {
        description = camping.text;
    } else if (currentLanguage === 'sv' && camping.country === 'se') {
        description = camping.text;
    } else {
        description = camping.textEnglish;
    }

    // Determine electricity status
    const electricityStatus = !camping.electricity || camping.electricity === "" || camping.electricity === 0 ?
        t('noInfo') :
        camping.electricity === -1 ?
            t('includedEl') :
            `${camping.electricity} ${camping.currency}`;

    return (
        <div className="camping-details-container">
            <div className="camping-card">
                <h2>{camping.title}</h2>
                <div className="contact-info">
                    {camping.phone && <div><img src={icPhone} alt="Phone" /> {camping.phone}</div>}
                    {camping.email && <div><img src={icEmail} alt="Email" /> <a href={`mailto:${camping.email}`} target="_blank" rel="noopener noreferrer">
                        {camping.email}
                    </a></div>}
                    {camping.webPage && <div><img src={icUrl} alt="Web Page" /> <a href={camping.webPage} target="_blank" rel="noopener noreferrer">{camping.webPage}</a></div>}
                </div>
                <p><strong>{t('price')} </strong> {camping.price} {camping.currency}</p>
                <p><strong>{t('electricity')} </strong> {electricityStatus}</p>
                <p><strong>{t('open')} :</strong> {camping.open}</p>
                <p><strong>{t('places')} </strong> {camping.places}</p>
                <p><strong>{t('ranking')} </strong>{camping.voters} {t('voters')}</p>
                <div className="ranking">
                    {rankingImages.map((src, index) => (
                        <img key={index} src={src} alt={`Rank ${index}`} />
                    ))}
                </div>

                <p><strong>{t('description')} </strong> {description}</p>

                <div className="icons">
                    <div className="icon-item">
                        <img src={camping.latrine ? icLatrine : icLatrineDisable} alt="Latrine" title="Latrine" />
                        <span>{t('latrine')}</span>
                    </div>
                    <div className="icon-item">
                        <img src={camping.seaView ? icNearLake : icNearLakeDisable} alt="Sea View" title="Sea View" />
                        <span>{t('seaView')}</span>
                    </div>
                    <div className="icon-item">
                        <img src={camping.pets ? icPetsAllowed : icPetsAllowedDisable} alt="Pets Allowed" title="Pets Allowed" />
                        <span>{t('petsAllowed')}</span>
                    </div>
                    <div className="icon-item">
                        <img src={camping.pool ? icPool : icPoolDisable} alt="Pool" title="Pool" />
                        <span>{t('pool')}</span>
                    </div>
                    <div className="icon-item">
                        <img src={camping.serviceStore ? icShop : icShopDisable} alt="Service Store" title="Service Store" />
                        <span>{t('serviceStore')}</span>
                    </div>
                </div>
            </div>

            <div className="map-container">
                <MapContainer center={position} zoom={11} scrollWheelZoom={false}>
                    <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={position} icon={customIcon}>
                        <Popup>
                            {camping.title}
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    );
};

export default CampingDetails;
