import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';

import '../css/header.css';
import '../css/global.css';
import logo from '../assets/logo.png';
import flagEN from '../assets/en.png';
import flagSV from '../assets/sv.png';
import flagNO from '../assets/no.png';
import flagDK from '../assets/dk.png';
import flagDE from '../assets/de.png';

const Header = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLanguageMenu = () => {
        setIsLanguageMenuOpen(!isLanguageMenuOpen);
    };

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        setIsLanguageMenuOpen(false);
    };

    const getLanguageFlag = () => {
        switch (i18n.language) {
            case 'en':
                return flagEN;
            case 'sv':
                return flagSV;
            case 'no':
                return flagNO;
            case 'dk':
                return flagDK;
            case 'de':
                return flagDE;
            default:
                return flagEN;
        }
    };

    return (
        <header>
            <nav className="navbar">
                <div className="navbar-left">
                    <NavLink exact to="/" className="logo-link">
                        <img src={logo} alt="CampApp Logo" className="logo" />
                    </NavLink>
                </div>
                <div className="navbar-right">
                    <button className="menu-toggle" onClick={toggleMenu}>
                        <span className="menu-icon"></span>
                        <span className="menu-icon"></span>
                        <span className="menu-icon"></span>
                    </button>
                    <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                        <li><NavLink exact to="/" activeClassName="active">{t('header_start')}</NavLink></li>
                        <li><NavLink to="/features" activeClassName="active">{t('header_feature')}</NavLink></li>
                        <li><NavLink to="/news" activeClassName="active">{t('news')}</NavLink></li>
                        <li><NavLink to="/about" activeClassName="active">{t('header_about')}</NavLink></li>
                        {/* <li><NavLink to="/admin" activeClassName="active">{t('header_admin')}</NavLink></li> */}
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.alstersjoapps.campingapp" target="_blank" rel="noopener noreferrer" className="external-link">
                                Android <FiExternalLink />
                            </a>
                        </li>
                        <li>
                            <a href="https://apps.apple.com/app/campapp-sverige/id1567206237" target="_blank" rel="noopener noreferrer" className="external-link">
                                iOS <FiExternalLink />
                            </a>
                        </li>
                    </ul>
                    <div className="language-selector">
                        <button className="language-toggle" onClick={toggleLanguageMenu}>
                            <img src={getLanguageFlag()} alt="Language Selector" className="language-flag" />
                        </button>
                        {isLanguageMenuOpen && (
                            <ul className="language-menu">
                                <li onClick={() => handleLanguageChange('sv')}>
                                    <img src={flagSV} alt="Svenska" className="language-flag" />
                                </li>
                                <li onClick={() => handleLanguageChange('en')}>
                                    <img src={flagEN} alt="English" className="language-flag" />
                                </li>
                                <li onClick={() => handleLanguageChange('no')}>
                                    <img src={flagNO} alt="Norsk" className="language-flag" />
                                </li>
                                <li onClick={() => handleLanguageChange('dk')}>
                                    <img src={flagDK} alt="Dansk" className="language-flag" />
                                </li>
                                <li onClick={() => handleLanguageChange('de')}>
                                    <img src={flagDE} alt="Deutsch" className="language-flag" />
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
