import React from 'react';
import '../css/statistics.css';

const Statistics = () => {
    return (
        <div className="statistics-container">
            <h2 className="statistics-title">Statistik</h2>
            <h4 className="statistics-subtitle">Här kommer finnas statistik i framtiden</h4>
        </div>
    );
};

export default Statistics;
