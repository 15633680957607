import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/footer.css'
import '../css/global.css'

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div className="footer-content">
                <div className="social-media">
                    <a href="https://www.facebook.com/campappnordic" target="_blank" rel="noopener noreferrer">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" />
                    </a>

                    <a href="https://www.instagram.com/campappnordic/" target="_blank" rel="noopener noreferrer">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" alt="Instagram" />
                    </a>
                </div>
                <div className="contact-info">
                    <p>{t('footer_contact')}<a href="mailto:info@campapp.se">info@campapp.se</a></p>
                </div>
                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} CampApp. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
