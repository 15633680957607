import React, { useState } from 'react';
import '../css/delete.css'; // Import the CSS file

const Delete = () => {
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    const fetchTitleAndOpenModal = async () => {
        setError('');
        setSuccessMessage('');
        if (!code.trim()) {
            setError('Code is required.');
            return;
        }

        try {
            const response = await fetch(`https://us-central1-campapp-195fe.cloudfunctions.net/getCampingByCode?code=${code}`);
            if (!response.ok) {
                throw new Error('Camping not found');
            }

            const data = await response.json();

            setTitle(data[0].data.title || 'No title found');
            setShowModal(true); // Show modal after fetching title
        } catch (err) {
            setError(`Error fetching camping title: ${err.message}`);
        }
    };

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const response = await fetch(`https://us-central1-campapp-195fe.cloudfunctions.net/deleteCampingByCode?code=${code}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                //body: JSON.stringify({ code })
            });

            if (!response.ok) {
                throw new Error('Failed to delete camping');
            }

            setSuccessMessage('Camping deleted successfully!');
            setTitle('');
            setCode('');
        } catch (err) {
            setError(`Error deleting camping: ${err.message}`);
        } finally {
            setShowModal(false);
        }
    };

    return (
        <div className="delete-container">
            <div className="delete-box">
                <h2>Ta bort Camping</h2>
                {error && <p className="error-message">{error}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
                <div className="form-group">
                    <label>Campingkod:</label>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <button onClick={fetchTitleAndOpenModal} className="delete-button">Ta bort</button>
                </div>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Bekräfta borttagning</h3>
                        <p>Vill du verkligen ta bort denna camping?  {title}?</p>
                        <div className="modal-buttons">
                            <button onClick={handleDelete} className="yes-button">Ja</button>
                            <button onClick={() => setShowModal(false)} className="no-button">Nej</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Delete;
