import React from 'react';
import '../css/about.css'
import '../css/global.css'
import bannerImage from '../assets/playground.jpeg'; // Replace with your banner image path
import developerImage from '../assets/developer.jpg'; // Replace with your developer image path
import { useTranslation } from 'react-i18next';


const About = () => {
    const { t } = useTranslation();

    return (
        <section id="about">
            <div className="about-banner">
                <img src={bannerImage} alt="About CampApp" className="banner-image" />
            </div>
            <div className="about-content">
                <h2>{t('aboutHeading')}</h2>
                <p>
                    {t('about1')}</p>
                <p>
                    {t('about2')}
                </p>
                <p>
                    {t('about3')}
                </p>
            </div>
            <div className="developer-info">
                <img src={developerImage} alt="Developer" className="developer-image" />
                <p>{t('developer')}</p>
            </div>
        </section>
    );
};

export default About;
