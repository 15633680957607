import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD1GMm6bMiKNFUhaYhJobbc7UX68NmcuYo",
    authDomain: "campapp-195fe.firebaseapp.com",
    databaseURL: "https://campapp-195fe-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "campapp-195fe",
    storageBucket: "campapp-195fe.appspot.com",
    messagingSenderId: "582295787452",
    appId: "1:582295787452:web:2e654ed47f6fd9ec16fdb9",
    measurementId: "G-TQVMVN6YGC"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
