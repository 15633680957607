// src/pages/ArticlePage.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import Quill styles
import '../css/articlepage.css';

const ArticlePage = () => {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const documentID = query.get('id');

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await fetch(`https://us-central1-campapp-195fe.cloudfunctions.net/getAllArticles`);
                if (!response.ok) {
                    throw new Error('Failed to fetch articles');
                }
                const articles = await response.json();
                console.log(articles)
                const foundArticle = articles.find(article => article.id === documentID);
                if (foundArticle) {
                    setArticle(foundArticle);
                } else {
                    setError('Article not found');
                }
            } catch (error) {
                console.error('Error fetching article:', error);
                setError('Failed to fetch article. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        if (documentID) {
            fetchArticle();
        }
    }, [documentID]);

    if (loading) return <p>Laddar...</p>;
    if (error) return <p className="error-message">{error}</p>;

    return (
        <div className="article-page">
            {article && (
                <>
                    <h1>{article.title}</h1>
                    <p><strong>Författare:</strong> {article.author}</p>
                    <p><strong>Publiceringsdatum:</strong> {new Date(article.publishedDate).toLocaleDateString()}</p>
                    <ReactQuill
                        value={article.content}
                        readOnly
                        theme="bubble"
                    />
                </>
            )}
        </div>
    );
};

export default ArticlePage;
