import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Import translation files
import translationEN from './locales/en.json';
import translationSV from './locales/sv.json';
import translationDK from './locales/dk.json';
import translationNO from './locales/no.json';
import translationDE from './locales/de.json';



const resources = {
    en: {
        translation: translationEN
    },
    sv: {
        translation: translationSV
    },
    no: {
        translation: translationNO
    },
    dk: {
        translation: translationDK
    },
    de: {
        translation: translationDE
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        //lng: 'sv', // default language
        fallbackLng: 'sv',
        debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
