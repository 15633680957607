import React from 'react';
import '../css/home.css'
import '../css/global.css'
import aerial from '../assets/aerial.jpeg';
import appStoreBadge from '../assets/appstore.png'; // Path to your App Store badge image
import playStoreBadge from '../assets/androidbadge.png'; // Path to your Play Store badge image
import { useTranslation } from 'react-i18next';


const Home = () => {
    const { t } = useTranslation();

    return (
        <section id="home">
            <div className="banner">
                <img src={aerial} alt="Aerial view of campsite" className="banner-image" />
            </div>
            <div className="content">
                <h1>{t('welcome_message')}</h1>
                <p>
                    {t('home1')}
                </p>
                <p>
                    {t('home2')}
                </p>
                <p>
                    {t('home3')}
                </p>
                <div className="download-buttons">
                    <a href="https://apps.apple.com/app/campapp-sverige/id1567206237" target="_blank" rel="noopener noreferrer">
                        <img src={appStoreBadge} alt="Download on the App Store" className="store-badge" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.alstersjoapps.campingapp" target="_blank" rel="noopener noreferrer">
                        <img src={playStoreBadge} alt="Get it on Google Play" className="store-badge" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Home;
