import React, { useState } from 'react';
import '../css/addnews.css';

const AddNews = () => {
    const [newsData, setNewsData] = useState({
        added: '',
        language: '',
        URL: '',
        publisher: '',
        titleSV: '',
        summarySV: '',
        titleDK: '',
        summaryDK: '',
        titleNO: '',
        summaryNO: '',
        titleDE: '',
        summaryDE: '',
        titleEN: '',
        summaryEN: '',
        isAd: false // New field
    });

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const fieldLabels = {
        added: 'Artikeldatum',
        titleDK: 'Titel (Danska)',
        summarySV: 'Sammanfattning (Svenska)',
        language: 'Orginalspråk',
        titleNO: 'Titel (Norska)',
        titleDE: 'Titel (Tyska)',
        URL: 'URL',
        summaryEN: 'Sammanfattning (Engelska)',
        titleEN: 'Titel (Engelska)',
        summaryDK: 'Sammanfattning (Danska)',
        summaryNO: 'Sammanfattning (Norska)',
        summaryDE: 'Sammanfattning (Tyska)',
        titleSV: 'Titel (Svenska)',
        publisher: 'Publiceringsplats',
        isAd: 'Annons:' // Label for new field
    };

    const languages = [
        { code: 'SV', label: 'Svenska' },
        { code: 'DK', label: 'Dansk' },
        { code: 'NO', label: 'Norsk' },
        { code: 'DE', label: 'Deutsch' },
        { code: 'EN', label: 'English' }
    ];

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setNewsData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validateForm = () => {
        const requiredFields = [
            'titleDK', 'summarySV', 'added', 'language', 'titleNO', 'titleDE',
            'URL', 'summaryEN', 'titleEN', 'summaryDK', 'summaryNO', 'summaryDE', 'titleSV',
            'publisher' // Add new field to validation
        ];

        for (let field of requiredFields) {
            if (!newsData[field].trim() && field !== 'isAd') {
                setError(`${fieldLabels[field]} is required.`);
                return false;
            }
        }

        setError('');
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch('https://us-central1-campapp-195fe.cloudfunctions.net/addNews', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(newsData)
                });

                if (response.ok) {
                    const result = await response.json();
                    setSuccessMessage(`News document added successfully with ID: ${result.id}`);
                    setNewsData({
                        added: '',
                        language: '',
                        URL: '',
                        publisher: '',
                        titleSV: '',
                        summarySV: '',
                        titleDK: '',
                        summaryDK: '',
                        titleNO: '',
                        summaryNO: '',
                        titleDE: '',
                        summaryDE: '',
                        titleEN: '',
                        summaryEN: '',
                        isAd: false // Reset new field
                    });
                } else {
                    setError('Failed to add news document. Please try again.');
                }
            } catch (error) {
                console.error('Error adding news document:', error);
                setError('Failed to add news document. Please try again.');
            }
        }
    };

    return (
        <div className="add-news">
            <h2>Lägg till nyhet</h2>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form className="news-form">
                {/* Custom field order */}
                {['added', 'language', 'URL', 'publisher', 'titleSV', 'summarySV', 'titleDK', 'summaryDK', 'titleNO', 'summaryNO', 'titleDE', 'summaryDE', 'titleEN', 'summaryEN', 'isAd'].map((key) => (
                    key === 'language' ? (
                        <div className="form-group" key={key}>
                            <label>{fieldLabels[key]} <span className="required">*</span>:</label>
                            <select
                                name={key}
                                value={newsData[key]}
                                onChange={handleChange}
                            >
                                <option value="">Select a language</option>
                                {languages.map(lang => (
                                    <option key={lang.code} value={lang.code}>{lang.label}</option>
                                ))}
                            </select>
                        </div>
                    ) : key === 'isAd' ? (
                        <div className="form-group" key={key}>
                            <label>{fieldLabels[key]}</label>
                            <input
                                type="checkbox"
                                name={key}
                                checked={newsData[key]}
                                onChange={handleChange}
                            />
                        </div>
                    ) : (
                        <div className="form-group" key={key}>
                            <label>{fieldLabels[key]} <span className="required">*</span>:</label>
                            <input
                                type={key === 'added' ? 'date' : 'text'}
                                name={key}
                                value={newsData[key]}
                                onChange={handleChange}
                            />
                        </div>
                    )
                ))}
                <button type="button" onClick={handleSubmit} className="save-button">
                    Lägg till
                </button>
            </form>
        </div>
    );
};

export default AddNews;
